import React from "react"
import { Link } from "gatsby"
import logo from "../../images/logo.svg"
import mail from "../../images/mail.svg"
import up from "../../images/Up.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./footer.styles.css"

const Footer = props => {
  const onClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  return (
    <>
      <div style={{ background: "  #fafafa", position: "relative" }}>
        <div className="up">
          <AnchorLink>
            <img
              src={up}
              alt="up"
              onClick={onClick}
              style={{ cursor: "pointer" }}
            />
          </AnchorLink>
        </div>
        <div className="container">
          <footer>
            <div>
              <img src={logo} alt='logo' />
            </div>
            <div className="menu_items">
              <ul>
                <li className={props.url === "/" ? "active" : ""}>
                  <Link to="/"><span className="footer-menu-text">Home</span></Link>
                </li>
                <li className={props.url === "/resources" ? "active" : ""}>
                  <Link to="/resources"><span className="footer-menu-text">Resources</span></Link>
                </li>
                <li className={props.url === "/about" ? "active" : ""}>
                  <Link to="/about"><span className="footer-menu-text">About</span></Link>
                </li>
              </ul>
            </div>
            <div>
                <p className="footer-text" style={{ fontSize: "16px", fontWeight: "600" }}>
                  Interested in working with us?
                </p>
                <br />
                <span className="footer-text" style={{ color: " #797979", fontSize: "16px" }}>
                  <img src={mail} alt='mail icon' />
                  {"  "} Email us at:
                </span>
                <p className="footer-text" style={{ fontSize: "16px" }}>contact@leanml.com</p>
            </div>
          </footer>
        </div>
      </div>
      <div
        className="copy_right"
        style={{
          color: "#797979",
          background: "#EDEDED",
          textAlign: "center",
          padding: "13px",
        }}
      >
        <span className="footer-text">
          © Copyright 2021 | LeanML
        </span>
      </div>
    </>
  )
}

export default Footer
