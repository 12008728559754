import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Location } from "@reach/router"

import "./header.styles.css"
import logo from "../../images/logo.svg"

const Header = props => {
  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />

        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap"
          rel="preload"
          as="style"
        />
      </Helmet>

      <header className="headers">
        <div className="container">
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
          <input
            className="menu-btn"
            type="checkbox"
            id="menu-btn"
            //onClick={onClick}
          />
          <label htmlFor="menu-btn" className="menu-icon" id="menu">
            <input style={{ display: "none" }} />
            <span className="navicon"></span>
          </label>
          <ul className="menu">
            <li className={props.url === "/" ? "active" : ""}>
              <Link to="/"><span className="menu-text">Home</span></Link>
            </li>
            <li className={props.url === "/resources" ? "active" : ""}>
              <Link to="/resources"><span className="menu-text">Resources</span></Link>
            </li>
            <li className={props.url === "/about" ? "active" : ""}>
              <Link to="/about"><span className="menu-text">About</span></Link>
            </li>
          </ul>
        </div>
      </header>
    </div>
  )
}

export default Header
